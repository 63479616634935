'use strict';
// import { Elm } from '../elm/Main.elm';

const {Elm} = require('../elm/Main');
var app = Elm.Main.init({
  node: document.querySelector('#app')
});

// Elm.Main.init({ node: document.querySelector('#app') });

function copyURLToClipboard() {
  document.querySelector('#encodedURL').select();
  document.execCommand('copy');
}

app.ports.copyURL.subscribe(copyURLToClipboard);
